import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [];

export const dictionary = {
		"/[[lang=locale]]/(index-page)": [15,[2],[3]],
		"/[[lang=locale]]/(non-auth)/accept-invite": [29,[2,13],[3]],
		"/[[lang=locale]]/(need-unauth)/account-recovery": [24,[2,11],[3]],
		"/[[lang=locale]]/(need-auth)/logout": [23,[2,4],[3]],
		"/[[lang=locale]]/(non-auth)/request-demo": [30,[2,13],[3]],
		"/[[lang=locale]]/(need-unauth)/reset-password-from-email": [25,[2,11],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-in": [26,[2,11,12],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up": [27,[2,11],[3]],
		"/[[lang=locale]]/(need-unauth)/super-admin": [28,[2,11],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(tenant)/(with-sidebar)/admin": [16,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(tenant)/(with-sidebar)/admin/DB-management": [17,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(tenant)/(with-sidebar)/admin/notice": [18,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(tenant)/(with-sidebar)/admin/usage": [19,[2,4,5,6,7],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(tenant)/(with-sidebar)/admin/user-list": [20,[2,4,5,6,7,8],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/chat": [21,[2,4,5,9,10],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/main": [22,[2,4,5,9],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';